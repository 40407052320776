import React, { useState } from "react";
import "./Projects.css";
import ImageSlider from "./ImageSlider/ImageSlider";
import ProjectModal from "./ProjectModal";
import ImageModal from "./ImageSlider/Modal";
import ScreenHeading from "../../Utilities/ScreenHeading/ScreenHeading";
import ScrollService from "../../Utilities/ScrollService";
import Animations from "../../Utilities/Animations";
import projects from "./ProjectsData";

const sliderStyle = {
  width: "100%",
  height: "280px",
  margin: "0 auto",
};

const ProjectCard = ({ project, openProjectModal, openImageModal }) => (
  <div className="cards" id={project.id}>
      <h4 className="title">{project.title}</h4>

    <div className="image">
      {project.slides.length > 0 ? (
        <div style={sliderStyle}>
          <ImageSlider slides={project.slides} openModal={openImageModal} />
        </div>
      ) : (
        <img
          src="https://via.placeholder.com/280"
          alt={`${project.title} placeholder`}
          className="img-withoutSlides"
        />
      )}
    </div>
    <div className="text">
      <button onClick={() => openProjectModal(project)} className="more-info-btn">
        En savoir plus
      </button>
      {project.techs && (
        <div className="tech-list_projectCards">
          {project.techs.map((tech, index) => (
            <img key={index} src={tech} alt="Tech Icon" />
          ))}
        </div>
      )}
    </div>
  </div>
);

export default function Projects(props) {
  const [showProjectModal, setShowProjectModal] = useState(false);
  const [showImageModal, setShowImageModal] = useState(false);
  const [currentProject, setCurrentProject] = useState(null);
  const [modalImageUrl, setModalImageUrl] = useState("");
  const [currentSlides, setCurrentSlides] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);

  let fadeInScreenHandler = (screen) => {
    if (screen.fadeInScreen !== props.id) return;
    Animations.animations.fadeInScreen(props.id);
  };
  // eslint-disable-next-line
  const fadeInSubscription = ScrollService.currentScreenFadeIn.subscribe(fadeInScreenHandler);

  const openProjectModal = (project) => {
    setCurrentProject(project);
    setShowProjectModal(true);
  };

  const closeProjectModal = () => {
    setShowProjectModal(false);
    setCurrentProject(null);
  };

  const openImageModal = (slides, index) => {
    setCurrentSlides(slides);
    setCurrentIndex(index);
    setModalImageUrl(slides[index].url);
    setShowImageModal(true);
  };

  const closeImageModal = () => {
    setShowImageModal(false);
    setModalImageUrl("");
  };

  const goToPrevious = () => {
    const isFirstSlide = currentIndex === 0;
    const newIndex = isFirstSlide ? currentSlides.length - 1 : currentIndex - 1;
    setCurrentIndex(newIndex);
    setModalImageUrl(currentSlides[newIndex].url);
  };

  const goToNext = () => {
    const isLastSlide = currentIndex === currentSlides.length - 1;
    const newIndex = isLastSlide ? 0 : currentIndex + 1;
    setCurrentIndex(newIndex);
    setModalImageUrl(currentSlides[newIndex].url);
  };

  return (
    <>
      <div className="screen-heading fade-in" id={props.id || ""}>
        <ScreenHeading title={"Projets"} subHeading={"Quelque Réalisations"} />
        <div className="cards-container">
          {projects.map((project, index) => (
            <ProjectCard
              key={index}
              project={project}
              openProjectModal={openProjectModal}
              openImageModal={(slides, index) => openImageModal(slides, index)}
            />
          ))}
        </div>
      </div>
      <ProjectModal showModal={showProjectModal} closeModal={closeProjectModal} project={currentProject} />
      <ImageModal
        showModal={showImageModal}
        closeModal={closeImageModal}
        imageUrl={modalImageUrl}
        goToPrevious={goToPrevious}
        goToNext={goToNext}
      />
    </>
  );
}
