import React from "react";
import { ToastContainer } from "react-toastify";
import Components from "./Components/Components";
import "./App.css";
import "react-toastify/dist/ReactToastify.css";
import emailjs from "emailjs-com";

function App() {
  React.useEffect(() => {
    emailjs.init("2ssjo8FtX0PwDNgJM");
  }, []);

  return (
    <div className="App">
      <ToastContainer />
      <Components />
    </div>
  );
}

export default App;
