import React from 'react'
import "./ScrollDown.css"

export default function ScrollDown() {
  return (
    
	  <div class="box">
      <span></span>
      <span></span>
      <span></span>
    </div>
    )
}
