import React, { useMemo } from "react";
import ScrollDown from "../../Utilities/ScrollDown/ScrollDown";
import ScreenHeading from "../../Utilities/ScreenHeading/ScreenHeading";
import ScrollService from "../../Utilities/ScrollService";
import Animations from "../../Utilities/Animations";
import "./AboutMe.css";

const SCREEN_CONSTANTS = {
  description:
    `Développeur Web diplômé d'OpenClassrooms, je suis animé par une passion dévorante pour la technologie et une curiosité insatiable. 
    Adepte du Frontend au Backend, avec une prédilection pour le développement côté client. 
    J'ai une expérience dans la stack MERN (MongoDB, Express, React, Node.js) et MySQL, me permettant de créer des applications web complètes. 
    Bien que React soit mon framework de prédilection, je possède également des compétences en Vue.js et Angular. Pour le styling, 
    j'adore jouer avec Sass et Tailwind pour donner vie à mes designs. 
    Je m'engage fortement envers les meilleures pratiques de développement, l'accessibilité web et l'optimisation SEO, 
    garantissant ainsi des produits de haute qualité, inclusifs et bien référencés.`,

  highlights: {
    heading: "Mes Passions & Intérêts :",
    bullets: [
      "Mélomane passionné, explorateur constant de nouveaux genres musicaux 🎵",
      "Amateur de discussions profondes et stimulantes autour d'un bon café ☕",
      "Gastronome virtuel, collectionneur de recettes culinaires ambitieuses 👨‍🍳",
      "Enthousiaste de technologie🖥️",
      // "Adepte de la résolution créative de problèmes et de l'innovation technique 💡",
    ],
  },
};
const AboutMe = ({ id }) => {
  
  // eslint-disable-next-line
  const fadeInScreenHandler = (screen) => {
    if (screen.fadeInScreen !== id) return;
    Animations.animations.fadeInScreen(id);
  };
  // eslint-disable-next-line
  const fadeInSubscription =
    ScrollService.currentScreenFadeIn.subscribe(fadeInScreenHandler);

  React.useEffect(() => {
    const fadeInSubscription =
      ScrollService.currentScreenFadeIn.subscribe(fadeInScreenHandler);
    return () => fadeInSubscription.unsubscribe();
    
  // eslint-disable-next-line
  }, [fadeInScreenHandler]);

  const renderHighlight = useMemo(() => {
    return SCREEN_CONSTANTS.highlights.bullets.map((value, i) => (
      <div className="highlight" key={i}>
        <div className="highlight-blob"></div>
        <span>{value}</span>
      </div>
    ));
  }, []);

  return (
    <>
      <div
        className="about-me-container screen-container fade-in"
        id={id || ""}
      >
        <div className="about-me-parent">
          <ScreenHeading
            title="À Propos de Moi"
            subHeading="Pourquoi me Choisir?"
          />
          <div className="about-me-card">
            <div
              className="about-me-profile"
              role="img"
              aria-label="Photo de profil"
            ></div>
            <div className="about-me-details">
              <p className="about-me-description">
                {SCREEN_CONSTANTS.description}
              </p>
              <div className="about-me-highlights">
                <h3 className="highlights-heading">
                  {SCREEN_CONSTANTS.highlights.heading}
                </h3>
                {renderHighlight}
              </div>
              <div className="about-me-options">
                <button
                  className="btn primary-btn"
                  onClick={() => ScrollService.scrollHandler.scrollToHireMe()}
                >
                  M'engager
                </button>

                <a href="/CV-Souleimane_ZEGGAI.pdf" download>
                  <button className="btn highlighted-btn">
                    CV
                  </button>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ScrollDown/>
    </>
  );
};

export default AboutMe;
