import React, { useState } from "react";
import Typical from "react-typical";
import { toast } from "react-toastify";
import emailjs from "emailjs-com";
import "./ContactMe.css";

import imgBanner from "../../Assets/contactMe/banner.webp";
import loading from "../../Assets/contactMe/loading.gif";

import ScreenHeading from "../../Utilities/ScreenHeading/ScreenHeading";
import ScrollService from "../../Utilities/ScrollService";
import Animations from "../../Utilities/Animations";

export default function ContactMe(props) {
  let fadeInScreenHandler = (screen) => {
    if (screen.fadeInScreen !== props.id) return;
    Animations.animations.fadeInScreen(props.id);
  };
  // eslint-disable-next-line
  const fadeInSubscription = ScrollService.currentScreenFadeIn.subscribe(fadeInScreenHandler);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [banner, setBanner] = useState("");
  const [bool, setBool] = useState(false);

  const handleName = (e) => {
    setName(e.target.value);
  };
  const handleEmail = (e) => {
    setEmail(e.target.value);
  };
  const handleMessage = (e) => {
    setMessage(e.target.value);
  };

  const submitForm = (e) => {
    e.preventDefault();

    if (name.length === 0 || email.length === 0 || message.length === 0) {
      setBanner("Tous les champs sont requis.");
      toast.error("Tous les champs sont requis.");
      return;
    }

    setBool(true);

    const templateParams = {
      from_name: name,
      from_email: email,
      message: message,
    };

    emailjs.send("service_8bmh9uh", "template_fxrpf1a", templateParams)
      .then((response) => {
        setBanner("Message envoyé avec succès !");
        toast.success("Message envoyé avec succès !");
        setBool(false);
        setName("");
        setEmail("");
        setMessage("");
      })
      .catch((error) => {
        console.error("Erreur lors de l'envoi du message :", error);
        setBanner("Une erreur est survenue, veuillez réessayer.");
        toast.error("Une erreur est survenue, veuillez réessayer.");
        setBool(false);
      });
  };

  return (
    <div className="main-container fade-in" id={props.id || ""}>
      <ScreenHeading subHeading={"Restons en Contact!"} title={"Me Contacter"} />
      <div className="central-form">
        <div className="colz">
          <h2 className="title">
            <Typical loop={Infinity} steps={["Restons en Contact 📧", 1000]} wrapper="span" />
          </h2>
          <a href="https://www.linkedin.com/in/souleimane-zeggai/" target="_blank" rel="noopener noreferrer" className="linkedIn">
            <i className="fa fa-linkedin-square socialsIcons"></i>
          </a>
          <a href="https://github.com/souleimane-z" target="_blank" rel="noopener noreferrer" className="github">
            <i className="fa fa-github-square socialsIcons"></i>
          </a>
          <a href="https://www.instagram.com/_souzzy_/" target="_blank" rel="noopener noreferrer" className="instagram">
            <i className="fa fa-instagram socialsIcons"></i>
          </a>
        </div>
        <div className="back-form">
          <div className="img-back">
            <h4>Ecrivez votre message ici!</h4>
            <img src={imgBanner} alt="background decoration" />
          </div>
          <form onSubmit={submitForm}>
            <p>{banner}</p>
            <label htmlFor="name">Nom
              <input type="text" onChange={handleName} value={name} />
            </label>
            <label htmlFor="email">Email
              <input type="email" onChange={handleEmail} value={email} />
            </label>
            <label htmlFor="message">Message
              <textarea type="text" onChange={handleMessage} value={message} />
            </label>
            <div className="send-btn">
              <button type="submit">
                send
                <i className="fa fa-paper-plane" />
                {bool ? (
                  <b className="load">
                    <img src={loading} alt="loading animation" />
                  </b>
                ) : (
                  ""
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
