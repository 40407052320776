import React, { useEffect } from "react";
import "./Modal.css";

const ImageModal = ({ showModal, closeModal, imageUrl, goToPrevious, goToNext }) => {
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "ArrowLeft") {
        goToPrevious();
      } else if (event.key === "ArrowRight") {
        goToNext();
      } else if (event.key === "Escape") {
        closeModal();
      }
    };

    if (showModal) {
      window.addEventListener("keydown", handleKeyDown);
    } else {
      window.removeEventListener("keydown", handleKeyDown);
    }

    return () => window.removeEventListener("keydown", handleKeyDown);
  }, [showModal, goToPrevious, goToNext, closeModal]);

  if (!showModal) return null;

  return (
    <div className="image-modal-overlay" onClick={closeModal}>
      <div className="image-modal-content" onClick={(e) => e.stopPropagation()}>
        <span className="image-modal-close" onClick={closeModal}>&times;</span>
        <img src={imageUrl} alt="Zoomed" className="image-modal-image" />
        <div className="image-modal-arrows">
          <div className="image-modal-arrow-left" onClick={goToPrevious}>❰</div>
          <div className="image-modal-arrow-right" onClick={goToNext}>❱</div>
        </div>
      </div>
    </div>
  );
};

export default ImageModal;