// ImageSlider.js
import React, { useState } from "react";
import "./ImageSlider.css";

export default function ImageSlider({ slides, openModal }) {
  const [currentIndex, setCurrentIndex] = useState(0);

  const goToPrevious = () => {
    const isFirstSlide = currentIndex === 0;
    const newIndex = isFirstSlide ? slides.length - 1 : currentIndex - 1;
    setCurrentIndex(newIndex);
  };

  const goToNext = () => {
    const isLastSlide = currentIndex === slides.length - 1;
    const newIndex = isLastSlide ? 0 : currentIndex + 1;
    setCurrentIndex(newIndex);
  };


  const containerSlides = {
    height: `100%`,
    position: `relative`,
  };
  const rightArrowStyle = {
    position: `absolute`,
    top: `50%`,
    transform: `translate(50%, -50%)`,
    right: `-15px`,
    fontSize: `35px`,
    color: `var(--dark-orange)`,
    zIndex: `10`,
    cursor: `pointer`,
  };
  const leftArrowStyle = {
    position: `absolute`,
    top: `50%`,
    transform: `translate(-50%, -50%)`,
    left: `-15px`,
    fontSize: `35px`,
    color: `var(--dark-orange)`,
    zIndex: `10`,
    cursor: `pointer`,
  };
  const slideStyles = {
    backgroundImage: `url(${slides[currentIndex].url})`,
    borderRadius: `10px`,
    width: `100%`,
    height: `100%`,
    backgroundPosition: `center`,
    backgroundSize: `cover`,
    cursor: `pointer`,
  };

  const handleImageClick = () => {
    openModal(slides, currentIndex);
  };

  return (
    <div style={containerSlides}>
      <div style={leftArrowStyle} onClick={goToPrevious} className="textShadow">❰</div>
      <div style={rightArrowStyle} onClick={goToNext} className="textShadow">❱</div>
      <div style={slideStyles} onClick={handleImageClick}><div className="zoom-icon">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM184 296c0 13.3 10.7 24 24 24s24-10.7 24-24V232h64c13.3 0 24-10.7 24-24s-10.7-24-24-24H232V120c0-13.3-10.7-24-24-24s-24 10.7-24 24v64H120c-13.3 0-24 10.7-24 24s10.7 24 24 24h64v64z"/></svg>
        </div></div> 
      
    </div>
  );
}
