// ProjectModal.js
import React , { useState, useEffect } from "react";
import "./ProjectModal.css";
import "./ImageSlider/Modal.css";
import "./ImageSlider/ImageSlider.css";

const ProjectModal = ({ showModal, closeModal, project }) => {
  const [showImageModal, setShowImageModal] = useState(false);
  const [modalImageUrl, setModalImageUrl] = useState("");
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "ArrowLeft") {
        goToPrevious();
      } else if (event.key === "ArrowRight") {
        goToNext();
      } else if (event.key === "Escape") {
        closeImageModal();
      }
    };

    if (showImageModal) {
      window.addEventListener("keydown", handleKeyDown);
    } else {
      window.removeEventListener("keydown", handleKeyDown);
    }

    return () => window.removeEventListener("keydown", handleKeyDown);
    // eslint-disable-next-line
  }, [showImageModal]);

  const openImageModal = (index) => {
    setCurrentIndex(index);
    setModalImageUrl(project.slides[index].url);
    setShowImageModal(true);
  };

  const closeImageModal = () => {
    setShowImageModal(false);
    setModalImageUrl("");
  };

  const goToPrevious = () => {
    const isFirstSlide = currentIndex === 0;
    const newIndex = isFirstSlide ? project.slides.length - 1 : currentIndex - 1;
    setCurrentIndex(newIndex);
    setModalImageUrl(project.slides[newIndex].url);
  };

  const goToNext = () => {
    const isLastSlide = currentIndex === project.slides.length - 1;
    const newIndex = isLastSlide ? 0 : currentIndex + 1;
    setCurrentIndex(newIndex);
    setModalImageUrl(project.slides[newIndex].url);
  };

  if (!showModal) return null;

  return (
    <div className="modal-overlay" onClick={closeModal}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <span className="modal-close" onClick={closeModal}>&times;</span>
        <div className="container_modal">
          <h1>{project.title}</h1>
          <div className="content_modal">
            <div className="image_modal">
              {project.slides.length > 0 ? (
                <div style={{ position: 'relative' }}>
                  <img
                    src={project.slides[currentIndex].url}
                    alt={project.slides[currentIndex].title}
                    onClick={() => openImageModal(currentIndex)}
                    style={{ cursor: "pointer" }}
                  />
                  <div className="loupe" onClick={() => openImageModal(currentIndex)}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM184 296c0 13.3 10.7 24 24 24s24-10.7 24-24V232h64c13.3 0 24-10.7 24-24s-10.7-24-24-24H232V120c0-13.3-10.7-24-24-24s-24 10.7-24 24v64H120c-13.3 0-24 10.7-24 24s10.7 24 24 24h64v64z"/></svg>
                  </div>
                  <div className="slider-arrow left" onClick={goToPrevious}>❰</div>
                  <div className="slider-arrow right" onClick={goToNext}>❱</div>
                </div>
              ) : (
                <img src="https://via.placeholder.com/280" alt="placeholder" />
              )}
            </div>
            <div className="description_modal" dangerouslySetInnerHTML={{ __html: project.content }} />
          </div>
          {project.techs && (
            <div className="tech-list">
              {project.techs.map((tech, index) => (
                <img key={index} src={tech} alt="Tech Icon" />
              ))}
            </div>
          )}
        </div>
      </div>
      {showImageModal && (
        <div className="image-modal-overlay" onClick={closeImageModal}>
          <div className="image-modal-content" onClick={(e) => e.stopPropagation()}>
            <span className="image-modal-close" onClick={closeImageModal}>&times;</span>
            <img src={modalImageUrl} alt="Zoomed" className="image-modal-image" />
            <div className="image-modal-arrows">
              <div className="image-modal-arrow-left" onClick={goToPrevious}>❰</div>
              <div className="image-modal-arrow-right" onClick={goToNext}>❱</div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProjectModal;