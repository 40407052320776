import React, { useState, useEffect, useMemo } from "react";
import ScrollDown from "../../Utilities/ScrollDown/ScrollDown";
import ScreenHeading from "../../Utilities/ScreenHeading/ScreenHeading";
import ScrollService from "../../Utilities/ScrollService";
import Animations from "../../Utilities/Animations";
import "./Resume.css";

const ResumeHeading = ({ heading, subHeading, secHeading, fromDate, toDate, description }) => (
  <div className="resume-heading">
    <div className="resume-main-heading">
      <div className="heading-bullet"></div>
      <span className="heading">{heading}</span>
      {fromDate && toDate && (
        <div className="heading-date">
          {fromDate + "-" + toDate}
        </div>
      )}
    </div>
    {subHeading && <div className="resume-sub-heading"><span>{subHeading}</span></div>}
    {secHeading && <div className="resume-second-heading"><span>{secHeading}</span></div>}
    {description && <div className="resume-heading-description"><span>{description}</span></div>}
  </div>
);

const resumeBullets = [
  { label: "Formation", logoSrc: "education.svg" },
  { label: "Expériences Récentes", logoSrc: "work-history.svg" },
  { label: "Autres Expériences", logoSrc: "work-history.svg" },
  { label: "Skills", logoSrc: "programming-skills.svg" },
  { label: "Loisirs", logoSrc: "interests.svg" },
];

const programmingSkillsDetails = [
  { skill: "HTML", ratingPercentage: 85 },
  { skill: "CSS", ratingPercentage: 85 },
  { skill: "JavaScript", ratingPercentage: 75 },
  { skill: "React JS", ratingPercentage: 62 },
  { skill: "Node JS", ratingPercentage: 52 },
  { skill: "Express JS", ratingPercentage: 52 },
  { skill: "Mongo DB", ratingPercentage: 52 },
  { skill: "MySQL", ratingPercentage: 52 },
  { skill: "SEO", ratingPercentage: 65 },
  { skill: "WCAG", ratingPercentage: 65 },
  { skill: "SASS", ratingPercentage: 65 },
  { skill: "Tailwind", ratingPercentage: 65 },
];

const Resume = (props) => {
  const [selectedBulletIndex, setSelectedBulletIndex] = useState(0);
  const [carouselOffSetStyle, setCarouselOffSetStyle] = useState({});

  useEffect(() => {
    const fadeInSubscription = ScrollService.currentScreenFadeIn.subscribe(fadeInScreenHandler);
    return () => fadeInSubscription.unsubscribe();
    // eslint-disable-next-line
  }, []);

  
    //eslint-disable-next-line
    const fadeInScreenHandler = (screen) => {
    if (screen.fadeInScreen !== props.id) return;
    Animations.animations.fadeInScreen(props.id);
  };

  const resumeDetails = useMemo(() => [
    <div className="resume-screen-container" key="Formation" id="formation">
      <ResumeHeading
        heading={"Développeur Web"}
        subHeading={"Titre Pro Bac +2 (Niveau 5)"}
        secHeading={"Openclassrooms"}
        fromDate={"2021"}
        toDate={"2022"}
      />
      <ResumeHeading
        heading={"BAC"}
        subHeading={"Économique & Social"}
        secHeading={"Lycée Ozanam | 📍 Lille"}
        fromDate={"2015"}
        toDate={"2015"}
      />
      <ResumeHeading
        heading={"BAFA"}
        subHeading={"La Ligue de l'Enseignement"}
        fromDate={"2014"}
        toDate={"2014"}
      />
    </div>,

    <div className="resume-screen-container" key="Expériences Récentes">
      <div className="experience-container">
        <ResumeHeading
          heading="COMMERCIAL"
          subHeading="Ranger FR | 📍 Marcq-en-Barœul"
          fromDate="2019"
          toDate="2019"
        />
        <div className="experience-description">
          <span className="resume-description-text resume-description-text_semibold">
            Commercial en porte-à-porte pour GDF-ENGIE
          </span>
        </div>
        <div className="experience-description">
          <span className="resume-description-text">
            • Prospecter dans le but de vendre un produit
          </span>
          <br />
          <span className="resume-description-text">
            • Adapter son discours à la personne en face de soi
          </span>
        </div>
      </div>
      <div className="experience-container">
        <ResumeHeading
          heading="Conseiller Clientèle"
          subHeading="DUACOM | 📍 Douai"
          fromDate="2018"
          toDate="2018"
        />
        <div className="experience-description">
          <span className="resume-description-text resume-description-text_semibold">
            Appels entrants pour La Poste.
          </span>
        </div>
        <div className="experience-description">
          <span className="resume-description-text">
            • Gérer les situations complexes dans un délai limité.
          </span>
        </div>
        <div className="experience-description">
          <span className="resume-description-text">
            • Prise en charge de nombreux clients toutes les 3min.
          </span>
        </div>
      </div>
    </div>,

    <div className="resume-screen-container" key="Autres Expériences">
      <div className="experience-container">
        <ResumeHeading
          heading="ANIMATEUR"
          subHeading="Municipalité | 📍 Harnes"
          fromDate="2012"
          toDate="2015"
        />
        <div className="experience-description">
          <span className="resume-description-text resume-description-text_semibold">
            Centre de Loisirs pendant les vacances scolaires
          </span>
        </div>
        <div className="experience-description">
          <span className="resume-description-text">
            • Manager un équipe d’animateur et former les nouveaux
          </span>
          <br />
          <span className="resume-description-text">
            • Mettre en place des projets
          </span>
          <br />
          <span className="resume-description-text">
            • S’adapter lors de situations compliquées.
          </span>
        </div>
      </div>
      <div className="experience-container">
        <ResumeHeading
          heading="Animateur"
          subHeading="Municipalité | 📍 Annay"
          fromDate="2017"
          toDate="2017"
        />
        <div className="experience-description">
          <span className="resume-description-text resume-description-text_semibold">
            Centre de Loisirs & Colonies de Vacances
          </span>
        </div>
        <div className="experience-description">
          <span className="resume-description-text">
            • Négocier auprès des prestataires
          </span>
        </div>
        <div className="experience-description">
          <span className="resume-description-text">
            • Régler des problèmes et des imprévus de dernières minutes
          </span>
        </div>
      </div>
    </div>,

    <div
      className="resume-screen-container programming-skills-container"
      key="Skills"
    >
      {programmingSkillsDetails.map((skill, index) => (
        <div className="skill-parent" key={index}>
          <div className="heading-bullet"></div>
          <span>{skill.skill}</span>
          <div className="skill-percentage">
            <div
              style={{ width: `${skill.ratingPercentage}%` }}
              className="active-percentage-bar"
            ></div>
          </div>
        </div>
      ))}
    </div>,

    <div className="resume-screen-container" key="Loisirs">
      <ResumeHeading
        heading="Musique 🎹"
        description="Goûts éclectiques, j'aime passer du jazz au rap jusqu'à la musique classique en faisant un détour vers le rock"
      />
      <ResumeHeading
        heading="Sport 🏉"
        description="Je suis un ancien rugbyman et j'aime pratiquer la boxe quand je le peux"
      />
      <ResumeHeading
        heading="Culture 📚"
        description="J'aime lire même si je pense ne pas assez le faire et bien sûr consommer des films à profusion"
      />
    </div>,
  ], []);

  const handleCarousel = (index) => {
    const offsetHeight = 360;
    setCarouselOffSetStyle({
      style: { transform: `translateY(${index * offsetHeight * -1}px)` },
    });
    setSelectedBulletIndex(index);
  };

  const getBullets = useMemo(() => {
    return resumeBullets.map((bullet, index) => (
      <div
        onClick={() => handleCarousel(index)}
        className={index === selectedBulletIndex ? "bullet selected-bullet" : "bullet"}
        key={index}
      >
        <img
          className="bullet-logo"
          src={require(`../../Assets/Resume/${bullet.logoSrc}`)}
          alt={`${bullet.label} icon`}
        />
        <span className="bullet-label">{bullet.label}</span>
      </div>
    ));
  }, [selectedBulletIndex]);

  const getResumeScreens = useMemo(() => {
    return (
      <div style={carouselOffSetStyle.style} className="resume-details-carousal">
        {resumeDetails}
      </div>
    );
  }, [carouselOffSetStyle.style, resumeDetails]);

  return (
    <>
      <div className="resume-container screen-container fade-in" id={props.id || ""}>
        <div className="resume-content">
          <ScreenHeading title={"Cursus"} subHeading={"Formation & Carrière"} />
          <div className="resume-card">
            <div className="resume-bullets">
              <div className="bullet-container">
                <div className="bullet-icons"></div>
                <div className="bullets">{getBullets}</div>
              </div>
            </div>
            <div className="resume-bullet-details">{getResumeScreens}</div>
          </div>
        </div>
      </div>
      <ScrollDown />
    </>
  );
};

export default Resume;
